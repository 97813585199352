//截图模块
import request from '../request.js';
// 截图调用工具
// /
export function  createImgTask(data) {
    return request({
        url:'module-web/screenshotData/createImgTask',
        method:'post',
        data
    })
}